<template>
  <div>
    <TitleComponent class="primary--text" :title="departmentDetails[0].Name"/>
    <v-container fluid>
    <v-row class="flex-child"  dense no-gutters="true">
      <v-col class="d-flex" cols="12" md="6" >
        <div class=""> 
         <v-icon
              large
              color="green darken-2"
            >
              mdi-head
            </v-icon>: 
        <strong>{{departmentDetails[0].Head}}</strong></div>
      </v-col>  
    </v-row>
   <v-row class="flex-child"  dense no-gutters="true"> 
     <v-col class="d-flex" cols="12" md="6" >
        <div class="">
          <v-icon
              large
              color="green darken-2"
            >
              mdi-account-search
            </v-icon>:
        <strong>{{departmentDetails[0].ContactPerson}}</strong></div>
      </v-col>  
    </v-row>

     <v-row class="flex-child"  dense no-gutters="true"> 
       <v-col class="d-flex" cols="12" md="6" >
        <div class="">
            <v-icon
              large
              color="green darken-2"
            >
              mdi-phone
            </v-icon>:
          <strong>{{departmentDetails[0].Phone}}</strong>
        </div>
       </v-col>
      </v-row>  
      <v-row class="flex-child"  dense no-gutters="true"> 
       <v-col class="d-flex" cols="12" md="6" >
          <div class="">
            <v-icon
              large
              color="green darken-2"
            >
              mdi-email
            </v-icon>:
          <strong>{{departmentDetails[0].Email}}</strong>
          </div>
      </v-col>
    </v-row>  
     
    
    </v-container> 

    <template>
      <v-tabs
        v-model="tab"
        background-color="primary"
        align-with-title
        
      >
        <v-tabs-slider ></v-tabs-slider>
        <v-tab v-for="item in items" :key="item" class="white--text" active-class="amber--text" >
          {{ item }}
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item"
      >
      <template v-if="item=='Downloadables'">
      <Downloadables :link="$route.params.departmentName"/>
      </template>  
      <template v-if="item=='Services'">
      <Services :link="$route.params.departmentName"/>
      </template>
      <template v-if="item=='Events'">
      Events
      </template>   
      </v-tab-item>
    </v-tabs-items>

  </div>
  
 
</template>

<script>
import TitleComponent from '@/components/TitleComponent';
import Downloadables from "@/views/Downloadables";
import Services from "@/views/Services";
//import departmentData from "@/json/department.json" DEPARTMENT HEAD :;

export default {
  name : 'Departments',
  components: {
    TitleComponent,Downloadables,Services
  },
  
  data () {
    return {
      departments : [],
      departmentName : this.$route.params.departmentName,
      departmentDetails:[],
      tab: null,
      items: ['Services', 'Events', 'Downloadables'],
    
    }
  },
  mounted() {
    //alert(this.departmentName);
    this.departmentDetails=this.getAllRecords(`/getAllRecords/departments/where link='`+ this.departmentName +`'/ID asc`);
    //this.departmentDetails=this.filterDepartment(this.departmentName); 
    //alert(this.departments);
  },

  
  watch: {
    '$route.params.departmentName': {
      handler:  function(departmentName){
        //alert(this.$route.params.departmentName)

        //this.departmentDetails=this.filterDepartment(departmentName)
        this.departmentDetails=this.getAllRecords(`/getAllRecords/departments/where link='`+ departmentName +`'/ID asc`);
      },
      deep: true,
      immediate: true
    }
  }, 
  

   methods : {

    getAllRecords:function(query){
      this.$axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);

                this.departmentDetails=response.data;
             //alert(this.departments);
                            
            }
      })
      .catch(error => { console.log(error)})
    },  

    filterDepartment: function(departmentName) {
      //shortcut to get department services
       //alert(this.departmentName);
       //this.departmentDetails=this.departments.filter( xdepartment => xdepartment.name==departmentName);
      return this.departments.filter( xdepartment => xdepartment.link==departmentName)
      //other way to extract data
      //return services.departmentName == this.departmentName
    }
  }
}
</script>

<style>
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>